import React, { useState } from 'react';
import { StaticQuery, graphql } from "gatsby";
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import Accordion from 'react-bootstrap/Accordion';
import { sortByMonth, addSlashToStr } from '../../utilities'

const PgSidebar = ({ category, activeArticle }) => {
     return <StaticQuery
          query={graphql`
               query PgSidebarQuery {
                    allContentfulPgCategory(sort: {fields: categoryName, order: DESC}, filter: {isYearCategory: {eq: true}}) {
                         edges {
                              node {
                                   contentful_id
                                   categoryName
                                   slug
                                   pg__category {
                                        contentful_id
                                        categoryName
                                        slug
                                        pg__article {
                                             contentful_id
                                             slug
                                             sidebarTitle
                                             createdAt
                                        }
                                   }
                              }
                         }
                    }
               }
          `}
          render={
               data => {
                    let sidebarContent = get(data, 'allContentfulPgCategory.edges');

                    const [activeMonthId, setActiveMonthId] = useState(() => {
                         var monthYearId = '';

                         sidebarContent.some(({ node: year }) => {
                              const monthAndYears = year.pg__category;

                              monthAndYears && monthAndYears.some((monthAndYear, index) => {
                                   if (typeof monthAndYear != 'undefined' && typeof category != 'undefined' && monthAndYear.slug == category.slug) {
                                        monthYearId = monthAndYear.contentful_id;
                                        return true; // return true to break some() loop 
                                   }
                              })

                              if (monthYearId != '') {
                                   return true;
                              }
                         })

                         return monthYearId;
                    });

                    const [activeYearId, setActiveYearId] = useState(() => {
                         var yearId = '';

                         sidebarContent.some(({ node: year }) => {
                              if (typeof category != 'undefined' && category.slug.includes(year.slug)) {
                                   yearId = year.contentful_id;
                                   return true;
                              }
                         })

                         return yearId;
                    });

                    const onClickYearAccordion = (id) => {
                         if (activeYearId == id) {
                              setActiveYearId('');
                         } else {
                              setActiveYearId(id)
                         }
                    }

                    const onClickMonthAccordion = (id) => {
                         if (activeMonthId == id) {
                              setActiveMonthId('');
                         } else {
                              setActiveMonthId(id);
                         }
                    }

                    return (
                         <Accordion defaultActiveKey={activeYearId}>
                              {
                                   sidebarContent.length > 0 && sidebarContent.map(({ node: year }) => {
                                        const monthAndYears = year.pg__category;
                                        const yearId = year.contentful_id;
                                        sortByMonth(monthAndYears)

                                        return (

                                             <div className="pg-category" key={yearId}>
                                                  <Accordion.Toggle
                                                       as="div"
                                                       className={`pg-category-title category-year ${activeYearId == yearId ? 'active' : ''}`}
                                                       eventKey={yearId}
                                                       onClick={() => onClickYearAccordion(yearId)}
                                                  >
                                                       <a href={`/pet-gazette/${year.slug}/`} onClick={e => e.stopPropagation()}>
                                                            {year.categoryName}
                                                       </a>
                                                       <button
                                                            type='button'
                                                            className="arrow-btn"
                                                            aria-expanded={activeYearId == yearId ? true : false}
                                                            aria-label="toggle"
                                                       >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.166" height="9.497" viewBox="0 0 16.166 9.497">
                                                                 <path id="Path_1629" data-name="Path 1629" d="M14.752,7.376,7.376,0,0,7.376" transform="translate(15.459 8.083) rotate(180)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </svg>

                                                       </button>
                                                  </Accordion.Toggle>
                                                  <Accordion.Collapse eventKey={yearId}>
                                                       <Accordion defaultActiveKey={activeMonthId}>
                                                            {
                                                                 monthAndYears && monthAndYears.map((monthAndYear) => {
                                                                      const categoryName = monthAndYear.categoryName || '';
                                                                      const monthId = monthAndYear.contentful_id;
                                                                      const month = categoryName.substr(0, categoryName.indexOf(' '));
                                                                      const articles = orderBy(monthAndYear.pg__article, 'createdAt', 'desc');

                                                                      return (
                                                                           <React.Fragment key={monthId}>
                                                                                <Accordion.Toggle
                                                                                     as="div"
                                                                                     className={`pg-category-title category-month ${activeMonthId == monthId ? 'active' : ''}`}
                                                                                     eventKey={monthId}
                                                                                     onClick={() => onClickMonthAccordion(monthId)}
                                                                                >
                                                                                     <a href={`/pet-gazette/${monthAndYear.slug}/`} onClick={e => e.stopPropagation()}>
                                                                                          {month}
                                                                                     </a>
                                                                                     <button
                                                                                          type='button'
                                                                                          className="arrow-btn"
                                                                                          aria-expanded={(activeYearId == yearId && activeMonthId == monthId) ? true : false}
                                                                                          aria-label="toggle"
                                                                                     >
                                                                                          <svg xmlns="http://www.w3.org/2000/svg" width="16.166" height="9.497" viewBox="0 0 16.166 9.497">
                                                                                               <path id="Path_1629" data-name="Path 1629" d="M198.089,3522l-7.376,7.376L183.337,3522" transform="translate(-182.63 -3521.293)" fill="none" stroke="#0047bb" stroke-width="2"/>
                                                                                          </svg>


                                                                                     </button>
                                                                                </Accordion.Toggle>
                                                                                <Accordion.Collapse eventKey={monthId}>
                                                                                     <ul className="list-article">
                                                                                          {articles && articles.map((article, i) => {
                                                                                               const slugs = `/pet-gazette/${monthAndYear.slug}/${article.slug}`;
                                                                                               const className = (typeof activeArticle != 'undefined' && activeArticle.contentful_id == article.contentful_id) ? 'active' : '';

                                                                                               return (
                                                                                                    <li key={i} className={className}>
                                                                                                         <a href={addSlashToStr(slugs)}>{article.sidebarTitle}</a>
                                                                                                    </li>
                                                                                               )
                                                                                          })}
                                                                                     </ul>
                                                                                </Accordion.Collapse>
                                                                           </React.Fragment >
                                                                      )
                                                                 })
                                                            }
                                                       </Accordion>
                                                  </Accordion.Collapse>
                                             </div>
                                        )
                                   })
                              }
                         </Accordion>
                    )
               }
          }
     />;
}

export default PgSidebar;