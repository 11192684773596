import React, { Component } from 'react';
import { Image } from '../Common/Image';
import PgSidebar from './PgSidebar';
import orderBy from 'lodash/orderBy';
import PgSidebarImage from './PgSidebarImage';

class PgCategory extends Component {
     constructor(props) {
          super(props);

          this.state = {
               articlePerPage: 6,
               recentArticles: 12,
          }
     }

     loadMoreArticle = () => {
          this.setState({ recentArticles: this.state.recentArticles + this.state.articlePerPage })
     }

     showLessArticle = () => {
          this.setState({ recentArticles: 12 })
     }

     // Get all articles of child categories
     getChildArticles = (categories) => {
          let childArticles = [];
          if (typeof categories != 'undefined' && categories != null) {
               categories.forEach((category) => {
                    let articleArr = this.getChildArticles(category.pg__category);
                    childArticles = [...childArticles, ...category.pg__article, ...articleArr];
               })
          } else {
               return [];
          }

          return childArticles;
     }

     render() {
          const { category } = this.props;
          let articles = []
          if (category.isYearCategory || category.pg__category) {
               const childArticles = this.getChildArticles(category.pg__category);
               articles = Array.isArray(category.pg__article) ? [...category.pg__article, ...childArticles] : [...childArticles];
               articles = articles.filter(article => {
                    return article;
               })
          } else {
               articles = category.pg__article;
          }

          let recentArticlesToShow = [];
          const articlesAfterSorting = orderBy(articles, 'createdAt', 'desc');
          recentArticlesToShow = articlesAfterSorting.slice(0, this.state.recentArticles);

          return (
               <section className={`pg-category-content`}>
                    <div className="container">
                         <div className="row">
                              <div className="col-lg-8">
                                   <div className="pg-category-articles">
                                        {category.categoryName && <h1>{category.categoryName}</h1>}
                                        <div className="row articles-wrap">
                                             {
                                                  recentArticlesToShow.length > 0 && (
                                                       recentArticlesToShow && recentArticlesToShow.map((article, i) => {
                                                            const slugs = `/pet-gazette/${article.category.slug}/${article.slug}/`;

                                                            return (
                                                                 <div className="col-md-6 article-block" key={i}>
                                                                      <div className="article-detail">
                                                                           <div className="image-wrap">
                                                                                <a href={slugs} tabIndex={-1}>
                                                                                     {article.featuredImage ? (<Image altOverride={(article.contentName) ? article.contentName : ''} image={article.featuredImage} />) : <img loading="lazy" src="/images/pet-gazette/article-thumbnail-default.png" alt="no-image" />}
                                                                                </a>
                                                                           </div>
                                                                           <div className="content">
                                                                                {article.sidebarTitle && (
                                                                                     <a aria-label={article.sidebarTitle} href={slugs}>
                                                                                          <h3>{article.sidebarTitle}</h3>
                                                                                     </a>
                                                                                )}
                                                                                {/* {article.shortDescription && <p>{article.shortDescription}</p>} */}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            )
                                                       })
                                                  )
                                             }
                                        </div>
                                        {
                                             (articles && (articles.length) > 12) ?
                                                  (
                                                       <div className="row layout-btn-gazette">
                                                            <div className="col-md-6 btn-gazette">
                                                                 <div className="btn-load-more">
                                                                      {
                                                                           (this.state.recentArticles >= articles.length) ?
                                                                                (<button className="button-white" onClick={this.showLessArticle}>Show less articles</button>)
                                                                                :
                                                                                (<button className="button-white" onClick={this.loadMoreArticle}>Load more articles</button>)
                                                                      }
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  ) : ''
                                        }
                                   </div>
                              </div>
                              <div className="d-none d-lg-block col-lg-4 sidebar-content">
                                   <PgSidebar category={category} />
                                   <div className="image-layout">
                                        <PgSidebarImage />
                                        {/* <a href="/veterinarians/"><img loading="lazy" src="/images/hrpg.jpg" alt="Add pet insurance to your voluntary benefit package" /></a> */}
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="d-lg-none sidebar-content">
                         <PgSidebar category={category} />
                         <div className='group-image-layout'>
                              <div className="image-layout">
                                   <PgSidebarImage />
                                   {/* <a href="/veterinarians/"><img loading="lazy" src="/images/hrpg.jpg" alt="Add pet insurance to your voluntary benefit package" /></a> */}
                              </div>
                         </div>
                    </div>
               </section>
          )
     }
}

export default PgCategory;