import React from 'react';
import { StaticQuery, graphql } from "gatsby";
// import { ContentfulBlockImage } from '../ContentfulBlockImage';
import { ContentfulBlockIconWithText } from '../ContentfulBlockIconWithText';

const PgSidebarImage = () => {
     return <StaticQuery
          query={graphql`
               query PgSidebarImageQuery {
                    contentfulPage(slug: {eq: "pet-gazette" }) {
                         rows {
                              ...ContentBlockFields
                         }
                    }
               }
          `}
          render={
               data => {
                    let sidebarImage = data.contentfulPage.rows ? data.contentfulPage.rows[0].blocks[0] : null;

                    return (
                         <ContentfulBlockIconWithText {...sidebarImage}></ContentfulBlockIconWithText>
                    )
               }
          }
     />;
}

export default PgSidebarImage;