import React from 'react';
import { graphql } from 'gatsby';
import { connect } from "react-redux";
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import PgCategory from '../components/BlockLibrary/PetGazette/PgCategory';
import '../sass/main.scss';

class PgCategoryTemplate extends React.Component {
     componentDidMount() {
          const brower = typeof window !== `undefined`;
          if (brower) {
               var getOmValue = localStorage.getItem("Petinsurance_OM");
               this.props.setTrackingCode(getOmValue);

               var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
               this.props.setTrackingPhone(getPhoneValue);
          }
     }

     render() {
          const category = get(this.props, 'data.contentfulPgCategory');
          const path = get(this.props, '*'); 3
          const page = get(this.props, 'data.contentfulPgCategory');

          return (
               <Layout>
                    <Seo
                         pageName={page.omniturePageNameLegacy || page.contentName}
                         specificPhone = {page.specificPhone}
                         trackingCode = {page.trackingCode}
                         title={category.seoTitleTag}
                         path={path}
                         canonical={category.seoCanonicalUrl ? category.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={category.seoMetaDescription ? category.seoMetaDescription.seoMetaDescription : ''}
                         robots={category.robotsDirective ? category.robotsDirective.robotsDirective : ''}
                         showAntiFlickerSnippet={category.showAntiFlickerSnippet}
                    />
                    <Header header={category.header}></Header>
                    <main id="main-content" role="main">
                         <PgCategory category={category} />
                    </main>
                    <Footer footer={category.footer}></Footer>
               </Layout>
          )
     }
}

const mapDispatchToProps = dispatch => {
     return {
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
     };
}

const mapStateToProps = (state) => {
     return {
          state,
     };
}
export default connect(mapStateToProps, mapDispatchToProps)(PgCategoryTemplate);

export const pageQuery = graphql`
     query PgCategoryBySlug($slug: String!) {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulPgCategory(slug: {eq: $slug }) {
               ...ContentfulPgCategoryFields
          }
     }
`
